@input-checkbox-styled-size: 12px;
@input-radio-styled-size: 10px;

input[type="checkbox"].styled {
  position: absolute;
  opacity: 0;
  z-index: 1;

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: inline-flex;
    align-items: flex-start;
    padding-right: 14px;
  }

  // Box.
  &+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    min-width: @input-checkbox-styled-size;
    width: @input-checkbox-styled-size;
    height: @input-checkbox-styled-size;
    background: #FFF;
    border: 1px solid @font-darkblue;
    box-sizing: border-box;

    position: relative;
    top: 3px;
  }

  // Box hover
  &:hover+label:before {
    /* background: @font-darkblue; */
  }

  // Box checked
  &:checked+label:before {
    background: @font-darkblue;
  }

  &.round+label:before {
    border-radius: 50%;
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked+label {
    // font-weight: 600;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 4px;

    width: 2px;
    height: 6px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover {
    cursor: pointer;
  }
}

/* --- radio */
input[type="radio"].styled {
  position: absolute;
  opacity: 0;

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-right: 8px;
  }

  // Box.
  &+label:before {
    content: '';
    margin-right: 4px;
    display: inline-block;
    vertical-align: text-top;
    width: @input-radio-styled-size;
    height: @input-radio-styled-size;
    background: #FFF;
    border: 1px solid @font-darkblue;

    border-radius: 50%;
  }

  // Box hover
  &:hover+label:before {
    /* background: @font-darkblue; */
  }

  // Box checked
  &:checked+label:before {
    background: #FFF;
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked+label {
    font-weight: 600;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;

    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: @font-darkblue;
  }
}

/* ----- button group */
.buttongroup {
  display: inline-flex;
  border: 1px solid @font-darkblue;
  border-radius: 20px;
  box-sizing: border-box;

  .bodyFont(10px);
  text-transform: uppercase;
}

.buttongroup__ {
  &option {
    margin: 2px;
    padding: 2px 5px;
    display: inline-flex;
    white-space: nowrap;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }

    &:hover {
      cursor: pointer
    }

    &--selected {
      background-color: @font-darkblue;
      color: @font-white;
      border-radius: 20px;

    }
  }
}

.collapser {
  &--open {
    .collapser__icon {
      transform: rotate(180deg);
    }
  }
}

.select-css {
  display: block;
  font-size: 12px;
  color: #39526b;
  letter-spacing: -0.2px;
  line-height: 1.3;
  padding: 8px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border-radius: 6px;
  border: solid 0.5px rgba(151, 151, 151, 0.28);
  // box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('../images/icon-arrow-down.svg'),linear-gradient(to bottom, #fff 100%,#fff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 45%, 0 0;
  background-size: 12px 12px, 100%;
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
  border-color: #888;
}

.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  outline: none;
}

.select-css option {
  font-weight: normal;
}

.select-css option:hover {
  font-weight: 600;
}