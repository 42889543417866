.dashboard {
  background-color: @background-white;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;

  padding: 15px 0 15px 30px;
  margin: 5px 0 0 0;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard__ {
  &chart-container {
    padding-top: 10px;
    display: grid;

    height: calc(100% - 10px);
    box-sizing: border-box;

    grid-template-columns: 1fr 1fr 296px;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
  }

  &col-1 {
    height: 100%;
    overflow: hidden;

    grid-column: 1;
    grid-row: 1;
  }

  &col-2 {
    height: 100%;
    overflow: hidden;
    grid-column: 2;
    grid-row: 1;
  }

  &col-3 {
    grid-column: 3;
    grid-row: span 2;
    overflow-y: scroll;
    border: solid 1px rgba(57, 82, 107, 0.25);
    border-radius: 6px;
    border-right: none;
  }

  &col-4 {
    height: 100%;
    overflow: hidden;

    grid-column: 1;
    grid-row: 2;
  }

  &col-5 {
    height: 100%;
    overflow: hidden;

    grid-column: 2;
    grid-row: 2;
  }

  /* Chart Maximized code */

  &col--maximized {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;

    .dashboard__chart-main-container {}

    .chart__legend {}

    .chart__legend-series-container {}

    & .dashboard__chart-title-icon-maximize {
      display: none;
    }

    & .dashboard__chart-title-icon-restore {
      display: initial;
    }

  }

  /* Chart Maximized code */

  &col--splitview {
    grid-row: 1 / span 2;

    .dashboard__chart-main-container {
      flex-direction: column;
    }

    .chart__legend {
      align-self: center;
      width: 80%;
      max-width: unset;
    }

    .chart__legend-series-container {
      display: flex;
      justify-content: space-evenly;
    }

    .chart__legend-series {
      min-width: 250px;
    }
  }

  &chart {
    border: 1px solid @border-charts;
    height: calc(100% - 5px);

    border-radius: 6px;
  }

  &chart-title-container {
    display: flex;
    padding-left: 16px;
    padding-right: 8px;
    background-color: @background-blue;
    align-items: center;
    max-height: 40px;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &chart-content {
    padding: 0 8px 8px 16px;
    background: white;

    .header {
      display: flex;
      align-items: center;

      .icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;

        &.patients {
          background-color: #39526b;
        }

        &.images {
          background-color: #c980ec;
        }

        &.clinical {
          background-color: #eed068;
        }
      }

      .title {
        font-size: 14px;
        flex-grow: 1;
      }

      .icon-toggle {
        cursor: pointer;
      }
    }

    .wrapper {
      display: flex;
      flex-flow: row;
      height: 40px;
      align-items: center;
      justify-content: space-evenly;
      border-top: solid 1px rgba(57, 82, 107, 0.2);

      div {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
      }

      .value {
        font-size: 14px;
        font-weight: 600;
      }

      .title {
        font-size: 10px;
      }

      &.patients {
        background-color: rgba(57, 82, 107, 0.1);
      }

      &.images {
        background-color: rgba(201, 128, 236, 0.1);
      }

      &.clinical {
        background-color: rgba(238, 208, 104, 0.1);
      }
    }

    &.datasets {
      .header {
        width: 100%;

        .wrapper {
          display: flex;
          flex-flow: column;
          align-items: baseline;
          margin-right: 10px;
          border: 0;

          p {
            font-size: 24px;
            font-weight: bold;
          }

          span {
            font-size: 12px;
            font-weight: normal;

          }
        }
      }

      .wrapper-content {
        display: flex;
        flex-flow: column;
        margin: 25px 0;

        .row {
          display: flex;
          flex-flow: row;
          font-size: 12px;

          span {
            color: rgba(57, 82, 107, 0.5);
          }

          p {
            margin-left: 5px;
            font-weight: 600;
          }
        }
      }

      &-numberz {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: last baseline;
        overflow-y: unset;

        em {
          font-style: italic;
          color: #39526b;
          opacity: 0.75;
          text-decoration: underline;
        }
      }

      .summary-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-y: unset;

        font-size: 12px;

        .label {
          margin-right: 5px;
          color: rgba(57, 82, 107, 0.5);
        }

        .value {
          color: "#39526b";

          &.bold {
            font-weight: 600;
          }
        }
      }

      &-barz {
        .outer-bar {
          height: 8px;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow-y: unset;

          .inner-bar {
            height: 100%;

            &.green {
              background-color: #afda85;
            }

            &.blue {
              background-color: #5aaaf6;
            }

            &.red {
              background-color: #ee6868;
            }

            &.gray {
              background-color: rgba(57, 82, 107, 0.1);
            }
          }
        }
      }
    }

    &.data-source,
    &.therapeutic {
      display: flex;

      .graph-container {
        display: flex;
        flex-flow: column;
        align-items: center;
        min-width: 120px;

        .graph {
          width: 70px;
          height: 70px;
        }

        p {
          margin-top: 10px;
          font-size: 12px;
          color: #39526b;
          opacity: 0.5;
          text-transform: uppercase;
        }
      }

      .info-container {
        flex-grow: 1;

        .info-header {
          display: flex;
          align-items: center;
          border-bottom: solid 1px rgba(57, 82, 107, 0.2);

          .icon {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 10px;

            &.neurology {
              background-color: #8480ec;
            }

            &.oncology {
              background-color: #afda85;
            }

            &.cardiology {
              background-color: #ee688f;
            }

            &.picnic {
              background-color: #5aaaf6;
            }

            &.flatiron {
              background-color: #eea868;
            }

            &.chess {
              background-color: #5eceb1;
            }
          }

          .title {
            font-size: 14px;
            flex-grow: 1;
          }

          .icon-toggle {
            cursor: pointer;
          }

        }
      }
    }

    &.data-source {
      .graph-container {
        .graph {
          border-color: #5aaaf6;
        }
      }
    }
  }

  &chart-title {
    .bodySemiBoldFont(16px);
    line-height: 40px;
    margin-right: 10px;
    text-transform: capitalize;

    &__filter {
      &-container {
        display: flex;
      }

      &-title {
        font-size: 10px;
        color: #ffffff;
        padding: 3px 5px;
        text-align: center;
        height: min-content;
        background: #647387;
        border-radius: 3px 0 0 3px;
        margin-right: 1px;
      }

      &-item {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        padding: 3px 5px;
        background-color: #39526b;
        text-align: center;
        height: min-content;
        margin-right: 1px;

        &__label {
          color: #ccddea;
        }

        &:last-child {
          border-radius: 0 3px 3px 0;
        }

        &:hover {
          &::after {
            margin-left: 5px;
            content: '×';
          }

          background: #be2355;
          cursor: pointer;
        }
      }
    }

    &__therapeutic-alt {
      h2 {
        display: inline;
        text-transform: capitalize;
      }
    }

    &-anchor {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &chart-title-icon {
    line-height: 40px;

    &-maximize {
      &:hover {
        cursor: pointer;
      }
    }

    &-restore {
      display: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &chart-main-container {
    height: calc(100% - 40px);
    display: flex;
    padding: 10px 15px;
    box-sizing: border-box;
  }

  &container-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
  }

  &radio-group {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &data_sets,
  &data_source,
  &therapeutic_areas,
  &demographics,
  &data_modalities {
    background-color: @background-blue;
    // border-right: none;
    // border-radius: 6px;
  }

  &title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &title {
    .bodySemiBoldFont(16px);
    margin-right: 20px;
  }

  &title-sub {
    .bodyFont(16px);
  }
}


.flex-spacer {
  flex-grow: 1;
}

.spacer-48 {
  width: 48px;
  height: 48px;
}

.spacer-32 {
  width: 32px;
  height: 32px;
}

.spacer-24 {
  width: 24px;
  height: 24px;
}

.spacer-18 {
  width: 18px;
  height: 18px;
}

.spacer-16 {
  width: 16px;
  height: 16px;
}

.spacer-8 {
  width: 8px;
  height: 8px;
}

.spacer-4 {
  width: 4px;
  height: 4px;
}

.spacer-2 {
  width: 4px;
  height: 4px;
}

/* Inside dashboard chart main container */
.chart__ {
  &chart-main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &chart {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    hr {
      background: #39526b;
      height: 1px;
      margin: 8px 0;
    }
  }

  &chart-header {
    margin-bottom: 10px;
  }

  &legend {
    max-width: 20%;
    min-width: 184px;
    // max-width: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    &-datasets {
      width: 30%;
      min-width: 232px;

      &-numberz {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: last baseline;
        overflow-y: unset;

        em {
          font-style: italic;
          color: #39526b;
          opacity: 0.75;
          text-decoration: underline;
        }
      }

      .summary-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-y: unset;

        font-size: 12px;

        .label {
          margin-right: 5px;
          color: rgba(57, 82, 107, 0.5);
        }

        .value {
          color: "#39526b";

          &.bold {
            font-weight: 600;
          }
        }
      }

      &-barz {
        .outer-bar {
          height: 8px;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow-y: unset;

          .inner-bar {
            height: 100%;

            &.green {
              background-color: #afda85;
            }

            &.blue {
              background-color: #5aaaf6;
            }

            &.red {
              background-color: #ee6868;
            }

            &.gray {
              background-color: rgba(57, 82, 107, 0.1);
            }
          }
        }
      }

      &-tagz {
        .tag-container {
          display: flex;
          flex-wrap: wrap;
          overflow-y: unset;

          .tag {
            border-radius: 3px;
            border: solid 1px #39526b;
            margin-right: 4px;
            margin-top: 4px;
            padding: 3px 5px;
            opacity: 0.75;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: -0.17px;
            text-transform: uppercase;

            &.extra {
              background: #39526b;
              color: white;
            }
          }
        }
      }
    }

  }

  &legend-subtitle {
    .bodyFont(14px, #9DA9B6);
    text-transform: uppercase;
    text-align: center;
  }

  &legend-series-container {
    margin-top: 15px;
  }

  &legend-series {
    margin-top: 5px;
    text-transform: capitalize;
  }

  &legend-series-title {
    .bodyFont(14px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    border-bottom: 1px solid @border-demographics;

    &-icon {
      background-color: red;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-left: -5px;

      &:first-child {
        margin-left: 2px;
      }

      &.ehr {
        background-color: #4a90e2;
      }

      &.image {
        background-color: #c980ec;
      }

      &.clinical {
        background-color: #eed068;
      }
    }

    &-text {
      padding-left: 8px;
      flex-grow: 1;

      &-small {
        font-size: 12px;
        color: #39526b;
      }

      &-pc {
        font-size: 12px;
        color: #39526b;
      }
    }

    &-arrow {
      img {
        width: 18px;
        height: 18px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &legend-series-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .bodyFont(14px);
    padding: 5px 0;

    min-height: 40px;
  }

  &legend-series-number {
    .bodySemiBoldFont(14px);
    line-height: 1;
  }

  &legend-series-anchor {
    font-size: 12px;
    color: #31526d;
    text-decoration: underline;
    cursor: pointer;
  }

  &legend-series-number-subtitle {
    .bodyLightFont(10px, @font-light-blue)
  }

  &legend-series-chart {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
}

/* Data modalities */
.chart__legend-series-content--modalities {
  justify-content: space-evenly;
  text-align: center;
}

#js-modalities-combinations-legend-series .chart__legend-series-content--modalities {
  &:nth-child(2) {
    border-top: 1px solid #39526b;
  }

  &:last-child {
    border-bottom: 1px solid #39526b;
  }
}

.chart__legend-series-content--overview {

  .chart__legend-series-content--overview-footer,
  .chart__legend-series-content--overview-content {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    margin-top: 8px;

    .wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;

      .value {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;

        span.chart__legend-series-title-icon {
          border-radius: 50%;
          width: 10px;
          height: 10px;

          &:nth-child(2) {
            margin-right: 0;
            margin-left: -5px;

          }

          &:nth-child(3) {
            margin-right: 0;
            margin-left: -5px;
          }

          &:last-child {
            margin-right: 2px;
          }

          &.ehr {
            background-color: #4a90e2;
          }

          &.image {
            background-color: #c980ec;
          }

          &.clinical {
            background-color: #eed068;
          }
        }

      }

      .title {
        display: flex;
        font-size: 8px;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }

  .chart__legend-series-content--overview-footer {
    align-items: center;
    height: 20px;
    background-color: rgba(104, 151, 199, 0.05);
  }

}

.modalities__ {
  &legend-colors {
    line-height: 12px;
    .bodyLightFont(11px);
    text-align: left;
    margin-top: 5px;
    padding-bottom: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &legend-text {}

  &legend-icon {
    display: inline-block;
    min-width: 4px;
    width: 4px;
    height: 10px;
    margin-right: 5px;
  }
}