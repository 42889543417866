.filter__ {
  &container {
    display: inline-block;
    box-sizing: border-box;
    .bodySemiBoldFont(16px);

    position: relative;
    margin-right: 5px;

    &--open {
      .filter__button:hover {
        background-color: @background-white;
        color: @font-darkblue;

        .filter__dropdown-icon {
          filter: none;
        }
      }

      .filter__dropdown-icon {
        transform: rotate(180deg);
      }
    }
  }

  &button {
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    background-color: @background-white;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
      background-color: @hover-blue;
      color: #FFF;

      .filter__dropdown-icon {
        filter: brightness(0) invert(1);
      }
    }

    &.filtered {
      background-color: #39526b;
      color: #FFF;

      .filter__dropdown-icon {
        filter: brightness(0) invert(1);
      }
    }
  }

  &counter {
    display: inline-block;
    padding: 5px;
    margin-right: 10px;

    border-radius: 3px;
    background-color: @background-darkblue;
    .bodyBoldFont(11px, @font-darkblue);

    line-height: 1;
  }

  &title {
    line-height: 32px;
  }

  &dropdown-content {
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    top: 42px;
    left: 0;
    background-color: #FFF;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    box-shadow: 0 4px 8px 0 rgba(57, 82, 107, 0.3);

    padding: 10px;

    width: 100%;

    &:before {

    }

    &--large {
      width: 500px;
      border-top-right-radius: 6px;
    }
  }

  /* A dirty trick so we don't see the drop shadow where it connects to the filter button*/

  &dropdown-blanker {
    background-color: #FFF;
    height: 4px;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 38px;
    left: 0;
  }

  &dropdown-content, &dropdown-blanker {
    display: none;
  }

  &dropdown-button {
    display: block;
    background-color: @font-darkblue;
    border-radius: 6px;
    .bodyBoldFont(16px, @font-white);
    text-align: center;
    padding: 5px;
    margin-top: 15px;

    &:hover {
      background-color: @hover-blue;
    }
  }

  &subtitle {
    text-transform: uppercase;
    .bodyBoldFont(11px);
    padding-bottom: 10px;
  }

  &list {
    .bodyFont(12px);
    line-height: 1.5;

    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;

    &--unlimited-height {
      max-height: none;
      overflow: visible;
    }

    &--custom-cols {
      max-height: 500px;
      column-count: 3;
      overflow: auto;

      li {
        width: 90px;
        margin-right: 20px;
      }
    }

    &--float {
      li {
        display: inline-block;
        width: 135px;
        margin-right: 20px;
        float: left;
        position: relative;

        &:nth-child(4n + 1) {
          clear: both;
        }
      }
    }

    li {
      margin-bottom: 5px;
    }
  }

  &list-li--2-lines {
    line-height: 1.5;
  }

  &list-inline {
    line-height: 1.5;
  }

  &datasources {
    width: 682px;
  }

  &patients {
    .bodyFont(12px);
  }

  &patients-line {
    display: flex;
    justify-content: flex-start;
  }

  &patients-age {
    width: 100%;
    border-radius: 5px;
  }

  &patients-country {
    border: 1px solid @font-darkblue;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    max-height: 220px;
    box-sizing: border-box;
  }
}
