/* Demographics - move to file*/
.demographics__ {
  &container {
    padding: 0 25px;
  }
  &group {
  }

  &subtitle {
    .bodyFont(12px);
    opacity: 0.5;
    text-transform: uppercase;
    padding: 10px 0;
  }

  &value {
    .bodyBoldFont(12px);
    display: inline-block;
    min-width: 40px;
  }

  &line {
    position: relative;
    height: 8px;
    line-height: 8px;
    background-color: @background-gray;
    margin-bottom: 10px;
  }

  &line-filled {
    display: inline-block;
    height: 8px;
    background-color: @chart-darkblue;
  }

  &map {
    position: relative;
    margin: 15px 0;

    /* Adjustment because I did the positions with bullet size 25px and now it's 75px*/
    top: -25px;
    left: -25px;
  }

  &map-image {
    position: relative;
    top: 25px;
    left: 25px;
  }

  &map-bullet-container {
    width: 75px;
    height: 75px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    &--northamerica {
      top: 40px;
      left: 33px;
    }

    &--southamerica {
      top: 94px;
      left: 58px;
    }

    &--asia {
      top: 39px;
      left: 165px;
    }

    &--europe {
      top: 39px;
      left: 109px;
    }

    &--australia {
      top: 105px;
      left: 196px;
    }

    &--africa {
      top: 77px;
      left: 113px;
    }
  }

  &map-bullet {
    width: 100%;
    height: 100%;
    background-color: @font-darkblue;
    border-radius: 50%;
  }
}