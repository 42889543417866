.timeline {
    &__form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-left: 8px;
        padding-right: 4px;

        button {
            border-radius: 3px;
            background-color: #39526b;
            font-size: 10px;
            font-weight: bold;
            color: #ffffff;
            border: none;
            padding: 4px;
            cursor: pointer;
        }
    }

    &__header {
        display: flex;
        margin-right: 30px;
        align-items: center;
    }
}

.dropdown {
    display: flex;
    border-radius: 6px;
    background-color: #eff4f9;
    width: max-content;
    min-width: 12rem;
    position: relative;

    &-open {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        background-color: #eff4f9;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba(57, 82, 107, 0.3);
    }

    &-closed {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        background-color: #eff4f9;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-left: 8px;
        padding-right: 4px;

        &>.row-img-selected {
            visibility: hidden;
        }

        &.selected {
            font-weight: bold;

            &>.row-img-selected {
                visibility: visible;
            }
        }

        &:not(:last-child):hover {
            font-weight: bold;
        }

        &:last-child {
            font-weight: 600;
        }

        &-stacked {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-direction: column;
        }
    }

    hr {
        display: block;
        height: 1px;
        border-top: 1px solid #39526b;
        margin: 8px 0 4px 0;
        padding: 0;
    }
}