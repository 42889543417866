@openSans: 'Open Sans', sans-serif;

/* util mixins */
.font(@size) {
  font-size: @size;
}

.font(@size, @color) {
  font-size: @size;
  color: @color;
}

/* Project resets */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

/* resets to be checked */

a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: none;
}

/* Fonts */
.titleFont() {
  font-family: @openSans;
  font-weight: 700;
}

.titleFont(@size) {
  font-family: @openSans;
  .font(@size);
  font-weight: 700;
}

.titleFont(@size, @color) {
  font-family: @openSans;
  .font(@size, @color);
  font-weight: 700;
}

.bodyFont() {
  font-family: @openSans;
  font-weight: normal;
}

.bodyFont(@size) {
  font-family: @openSans;
  font-weight: normal;
  .font(@size);
}

.bodyFont(@size, @color) {
  font-family: @openSans;
  font-weight: normal;
  .font(@size, @color);
}

.bodyBoldFont() {
  font-family: @openSans;
  font-weight: bold;
}

.bodyBoldFont(@size) {
  font-family: @openSans;
  .font(@size);
  font-weight: bold;
}

.bodyBoldFont(@size, @color) {
  font-family: @openSans;
  .font(@size, @color);
  font-weight: bold;
}

.bodySemiBoldFont() {
  font-family: @openSans;
  font-weight: 600;
}

.bodySemiBoldFont(@size) {
  font-family: @openSans;
  .font(@size);
  font-weight: 600;
}

.bodySemiBoldFont(@size, @color) {
  font-family: @openSans;
  .font(@size, @color);
  font-weight: 600;
}

.bodyLightFont() {
  font-family: @openSans;
  font-weight: 300;
}

.bodyLightFont(@size) {
  font-family: @openSans;
  .font(@size);
  font-weight: 300;
}

.bodyLightFont(@size, @color) {
  font-family: @openSans;
  .font(@size, @color);
  font-weight: 300;
}

/* body */

body {
  background-color: @background-gray;
  color: @font-darkblue;
  .bodyFont(12px);
  overflow: hidden;
}

html,
body {
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: @background-white;
}

::-webkit-scrollbar-track {}

::-webkit-scrollbar-thumb {
  background-color: @chart-darkblue;
  border-radius: 10px;
}


/* General utils */
.list-unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.hidden {
  display: none;
}

div.hidden {
  display: none;
}

/* Main containers */
.page {
  height: 100%;
  padding: 10px 15px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}

.single {
  background: white;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.page-filters {
  display: flex;
  align-items: center;
}

.page-date-filter {
  height: 144px;
  background: white;
}


.main-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 102px;
  height: 102px;
  margin: 8px;
  border: 10px solid @font-darkblue;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: @font-darkblue transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#toaster-oven {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.line-height-checkboxes {
  line-height: 1.5;
}